import Vue from 'vue';

export default {
    namespaced: true,
    actions: {
        initializeUser({ commit }) {
            const { token } = localStorage;
            Vue.prototype.setHeader('Authorization', `Bearer ${token}`);
            return Vue.prototype.get('session-user/').then(response => {
                commit('uploadCurrentUser', response.data);
            });
        },

        authorizeUser({ dispatch }, code) {
            const params = {
                code,
            };
            return Vue.prototype.get('session/', params).then(response => {
                localStorage.token = response.data;
                dispatch('initializeUser');
            });
        },

        sendChoice({ dispatch }, value) {
            const data = {
                text: value,
            };
            return Vue.prototype.post('choice/', data).then(() => {
                dispatch('initializeUser');
            });
        },

        fetchTarget({ commit }) {
            return Vue.prototype.get('target/').then(response => {
                commit('uploadTarget', response.data);
            });
        },

        targetViewed({ dispatch }) {
            return Vue.prototype.post('target/').then(() => {
                dispatch('initializeUser');
            });
        },
    },
    mutations: {
        uploadCurrentUser(state, user) {
            state.currentUser = user;
        },

        uploadTarget(state, user) {
            state.targetUser = user;
        },
    },
    state: {
        currentUser: {},
        targetUser: {},
        modifications: {
            1: {
                id: 1,
                text: 'Ты должен к подарку приложить сгенерированные нейросетью смешные фотки получателя. (К примеру, Kandinsky 2.2 может такое)',
            },
            2: {
                id: 2,
                text: 'Тебе нужно упаковать подарок так, чтобы он был в форме какого-то другого предмета (к примеру, толстовку в виде велосипеда)',
            },
            3: {
                id: 3,
                text: 'С подарком ты должен приложить что-то, что связано с Crysto',
            },
            4: {
                id: 4,
                text: 'Ты должен воспроизвести 3 фотки получателя в реальной жизни (к примеру, сделать фотку, как ты сидишь в том же положении, что и цель) и приложить их к подарку (можно QR-кодом)',
            },
            5: {
                id: 5,
                text: 'Ты должен сделать тикток-мем про своего получателя и приложить QR-код на видео к подарку (сниматься самому не обязательно)',
            },
            6: {
                id: 6,
                text: 'Вместе с подарком должно лежать что-то съедобное',
            },
            7: {
                id: 7,
                text: 'С подарком ты должен приложить что-то с символом 2024 года',
            },
        },
        usersChoices: {
            1: {
                id: 1,
                name: 'Мем',
                description: 'Подарить что-то, связанное с мемом',
                parentalName: 'мем',
                text:
                    'Итак, посмотри тщательно на эти 8 мемов.b' +
                    'Среди них должен быть тот, который тебе понравится, выбери его.b' +
                    'Ты можешь нажать на картинку для предпросмотра.b',
            },
            2: {
                id: 2,
                name: 'Планы на год',
                description: 'Подарить что-то, связанное с планами на год',
                parentalName: 'планы на год',
                text: 'Подумай и опиши свои планы на год.b',
            },
            3: {
                id: 3,
                name: 'Жанр',
                description: 'Подарить что-то, связанное с жанром',
                parentalName: 'жанр',
                text: 'Посмотри на эти жанры и выбери тот, который тебе нравится больше всего.b',
                values: [
                    'драма',
                    'детектив',
                    'боевик',
                    'музыкальный',
                    'комедия',
                    'приключения',
                    'исторический',
                    'байопик',
                    'фэнтези',
                    'научная фантастика',
                ],
            },
            4: {
                id: 4,
                name: 'Счастливый билет',
                description: 'Описать любое желание',
                parentalName: 'счастливый билет',
                text: 'Что ж, ты счастливчик, можешь написать в поле ниже всё, что угодноb',
            },
            5: {
                id: 5,
                name: 'Цвет',
                description: 'Подарить что-то, связанное с цветом',
                parentalName: 'цвет',
                text:
                    'Ты можешь выбрать любой цвет, который ты захочешь, просто напиши его в поле.b' +
                    'Можешь указать его в хексе, в ргб, а можешь просто текстом.b',
            },
            6: {
                id: 6,
                name: 'Киноман',
                description: 'Подарить что-то, связанное с фильмом или сериалом',
                parentalName: 'киноман',
                text: 'Всё просто, выбери любой фильм или сериал из списка.b',
                values: [
                    'Звездные войны',
                    'Гарри поттер',
                    'Властелин колец',
                    'Мстители',
                    'Как я встретил вашу маму',
                    'Пираты Карибского моря',
                    'Назад в будущее',
                    'Сумерки',
                    'Друзья',
                    'Матрица',
                    'Люди Икс',
                ],
            },
            7: {
                id: 7,
                name: 'Анекдотики',
                description: 'Подарить что-то, связанное с анекдотом',
                parentalName: 'анекдот',
                text: 'Расскажи свой любимый анекдотb',
            },
        },
    },
    getters: {
        token() {
            return localStorage.token;
        },

        choiceById: state => id => state.usersChoices[id],
    },
};
